import CryptoJS from "crypto-js";

export function debounce(func: (...args: unknown[]) => void, delay: number) {
  let timer: NodeJS.Timeout | null;

  return (...args: unknown[]) => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
}

export const truncate = (str: string, n: number) => {
  if (str.length < n) return str;
  return str.slice(0, n - 1) + "...";
};

export const restrictTab = (
  e: KeyboardEvent,
  firstFocus: HTMLElement,
  lastFocus: HTMLElement,
) => {
  if (e.key !== "Tab") return;
  const currentFocus = document.activeElement;

  if (e.shiftKey) {
    if (currentFocus === firstFocus) {
      e.preventDefault();
      lastFocus.focus();
    }
    return;
  }

  if (currentFocus === lastFocus) {
    e.preventDefault();
    firstFocus.focus();
  }
};

export function formatPrice(value: number | string) {
  if (typeof value === "string") {
    return value;
  }
  if (Number.isInteger(value)) {
    return value.toFixed(0);
  }
  return value.toFixed(2);
}

export const isInternalEmail = (email: string) => {
  const userEmail = email.toLowerCase();
  const internalEmails = [
    "staff.aussiebroadband.com.au",
    "team.aussiebroadband.com.au",
  ];
  return internalEmails.some((internalEmail) =>
    userEmail.includes(internalEmail),
  );
};

export const parseNumber = (x: unknown) => {
  return parseFloat(Number(x).toFixed(2));
};

// Encrypted Session Storage -> taken from leadmanager branch, will need to merge/reconcile when either branch is merged
const sessionStorageEncryptionKey = `${import.meta.env.SESSION_STORAGE_ENCRYPTION_KEY}`;

export const setEncryptedSessionStorage = (key: string, data: unknown) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    sessionStorageEncryptionKey,
  ).toString();

  sessionStorage.setItem(key, encryptedData);
};

export const getDecryptedSessionStorage = (key: string) => {
  const encryptedData = sessionStorage.getItem(key);
  if (!encryptedData) return null;

  const decryptedData = CryptoJS.AES.decrypt(
    encryptedData,
    sessionStorageEncryptionKey,
  );
  const parsedData = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));

  return parsedData;
};

export const setEncryptedLocalStorage = (key: string, data: unknown) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    sessionStorageEncryptionKey,
  ).toString();

  localStorage.setItem(key, encryptedData);
};

export const getDecryptedLocalStorage = (key: string) => {
  const encryptedData = localStorage.getItem(key);
  if (!encryptedData) return null;

  const decryptedData = CryptoJS.AES.decrypt(
    encryptedData,
    sessionStorageEncryptionKey,
  );
  const parsedData = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));

  return parsedData;
};

export const getDebugValue = () => {
  const config = useRuntimeConfig();
  const { hostname } = useRequestURL();
  return config.public.appEnv === "local" || hostname.includes("pages.dev");
};

export const getApiUrl = (path: string) => {
  const config = useRuntimeConfig();
  return config.public.appApi + path;
};

const today = new Date();
export const timeZone =
  new Intl.DateTimeFormat("en-AU", {
    timeZone: "Australia/Sydney",
    timeZoneName: "short",
  })
    .format(today)
    .indexOf("AEDT") > -1
    ? "AEDT"
    : "AEST";
